import { render, staticRenderFns } from "./TestimonialCreate.vue?vue&type=template&id=100b0674&scoped=true&"
import script from "./TestimonialCreate.vue?vue&type=script&lang=js&"
export * from "./TestimonialCreate.vue?vue&type=script&lang=js&"
import style0 from "./TestimonialCreate.vue?vue&type=style&index=0&id=100b0674&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "100b0674",
  null
  
)

export default component.exports