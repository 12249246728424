import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTestimonialCreate() {
  const toast = useToast()
  const statusOptions = [
    { label: 'Activate', value: 'Activate' },
    { label: 'Inactivate', value: 'Inactivate' },
  ]

  const fetchBusinessLine = async () => {
    try {
      const response = await store.dispatch('app-testimonial/fetchBusinessLine');
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetch business line.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const addTestimonial = async (testimonial) => {
    try {
      const response = await store.dispatch('app-testimonial/addTestimonial', { ...testimonial });
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error create testimonial.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const deleteTestimonial = async (id) => {
    try {
      const response = await store.dispatch('app-testimonial/deleteTestimonial', id);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error delete testimonial.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const uploadImage = async (images) => {
    try {
      const response = await store.dispatch('app-testimonial/uploadImage', images);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error upload image',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    toast,
    statusOptions,
    uploadImage,
    addTestimonial,
    fetchBusinessLine,
    deleteTestimonial,
  }
}
